<template>
  <el-row class="text-center">
    <el-col
      :span="20"
      :offset="2"
    >
      <label class="pz-offer-color-primary pz-font-size-lg">
        <b>
          {{ $t('forms_flujo.PzSimulator.recived') }}
        </b>
        <label class="pz-offer-color-secondary pz-font-size-xl">
          {{ getMontoCuota }}
        </label>
        <b>
          {{ $t('forms_flujo.PzSimulator.tasas') }}
        </b>
        <label
          v-if="myForm.form.tasa"
          class="pz-offer-color-secondary pz-font-size-xl"
        >&nbsp;
          {{ myForm.form.tasa }} %
        </label>
        <label
          v-else
          class="pz-offer-color-secondary pz-font-size-xl"
        >
          ...&nbsp;%
        </label>
      </label>
    </el-col>
  </el-row>
</template>

<script>
    import PzOfertaSolicitudDefault from "@/components/Flujo/Simuladores/Ofertas/baseOferta";

    export default {
        name: "PzOfertaDescuentosDefault",
        mixins: [PzOfertaSolicitudDefault],
    }
</script>